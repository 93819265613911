import React from 'react';

import { useDispatch, useSelector } from "react-redux"
import { Dispatch, RootState } from "../../store/store"
import { useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import ToursList from './list/ToursList';
import { DateTime } from 'luxon';

/**
 * Displays list of closest days with tours
 *  
 */
function ToursDaysList(props: { project: string, days?: number, initDate?: DateTime }): JSX.Element {

    const { t } = useTranslation();
    const dispatch = useDispatch<Dispatch>();
    useEffect(() => {
        dispatch.toursCalendar.initialize({ translate: t, filterByProject: props.project, getAllUpcomingTours: props?.initDate ? false : true, days: props.days, initDate: props.initDate, });
    }, [])

    const daysTours = useSelector((state: RootState) => state.toursCalendar.cachedDaysAvailableTours);

    return (<>{daysTours.map((dayTours) => <ToursList dayTours={dayTours} />)}</>);
}

export default ToursDaysList;
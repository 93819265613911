import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const BulletPoint = ({ icon, title, children }: { icon: IconProp, title: string, children: JSX.Element }) => {
    return (
        <div className="container p-0 py-3">
            <div className="row">
                <div className="col-3 text-center-sm">
                    <FontAwesomeIcon icon={icon} size={"3x"} />
                </div>
                <div className="col-9">
                    <h4 className="fw-bold mb-0 py-3">{title}</h4>
                    {children}
                </div>
            </div>

        </div>
    )
}

export default BulletPoint;